import React from 'react';
import '../style/privacy-terms.css';

const Privacy = () => {
    return (
        <div className="Privacy-Container">
            <h1>Privacy Policy</h1>
            <p>Welcome to The Apps Vault, accessible from https://theappsvault.com. We prioritize the privacy of our
                visitors and are committed to protecting your personal data. This Privacy Policy details the types of
                information we collect and how it is used and processed.</p>
            <p>Should you have any questions or require further information regarding our Privacy Policy, please contact
                us at niv@theappsvault.com.</p>
            <p>This Privacy Policy exclusively addresses information collected through our website and does not cover
                information collected offline or via other channels.</p>

            <h2>Consent</h2>
            <p>By accessing and using our website, you hereby consent to our Privacy Policy and agree to its terms.</p>

            <h2>Information Collection</h2>
            <p>The specific types of personal information collected and the purposes for which it is used are clearly
                identified at the time of collection.</p>
            <p>Direct interactions with us, such as through emails or other communications, may result in the collection
                of additional information about you, including your name, email address, phone number, and the contents
                of your messages or attachments.</p>
            <p>Should you register on our site, you may be asked to provide details such as your name, company name,
                address, email address, and telephone number.</p>

            <h2>Usage of Your Information</h2>
            <p>The information we collect is utilized in several ways:</p>
            <ul>
                <li>To maintain and operate our website efficiently</li>
                <li>To personalize, enhance, and expand our website</li>
                <li>To analyze and understand how our services are used</li>
                <li>To develop new products, services, and features</li>
                <li>To communicate with you, including for customer service, updates, and marketing</li>
                <li>To send you emails and other communications</li>
                <li>To detect and prevent fraud</li>
            </ul>

            <h2>Log Files</h2>
            <p>As part of our hosting services' analytics, The Apps Vault employs a standard practice of using log
                files. These files log visitors when they access websites and collect information including IP
                addresses, browser type, Internet Service Provider (ISP), date and time stamps, referring/exit pages,
                and click numbers. This information is not linked to any information that is personally
                identifiable.</p>

            <h2>Cookies and Web Beacons</h2>
            <p>To enhance your online experience, we use 'cookies' to store preferences and track information about your
                interactions with our website.</p>

            <h2>Google DoubleClick DART Cookie</h2>
            <p>Google, as a third-party vendor, utilizes cookies to serve ads on our site. The use of DART cookies by
                Google enables it to serve ads to our users based on their visit to our sites and other sites on the
                Internet. Users may opt out of the use of the DART cookie by visiting the Google ad and content network
                privacy policy.</p>

            <h2>Advertising Partners</h2>
            <p>Our advertising partners, including Google, may use cookies and web beacons. Their privacy policies are
                accessible here:</p>
            <ul>
                <li><a href="https://policies.google.com/technologies/ads">Google Ad Policies</a></li>
            </ul>

            <h2>Third-Party Privacy Policies</h2>
            <p>The Apps Vault's Privacy Policy does not extend to other advertisers or websites. We recommend reviewing
                the respective privacy policies of these third-party entities for more detailed information on their
                practices.</p>

            <h2>CCPA Privacy Rights (Do Not Sell My Personal Information)</h2>
            <p>California residents have specific rights regarding their personal information under the CCPA. These
                rights include requesting businesses to disclose the categories of personal data collected, to delete
                personal data, and to opt-out from the sale of their personal data.</p>

            <h2>GDPR Data Protection Rights</h2>
            <p>We are committed to ensuring that your data protection rights are fully respected. These rights include
                the right to access, correct, erase, restrict processing of, object to processing of, and request data
                portability of your personal data.</p>

            <h2>Children's Information</h2>
            <p>Protecting the privacy of young children is especially important. If you believe that your child has
                provided us with personal information without your consent, please contact us immediately to have it
                removed.</p>
        </div>


    );
};

export default Privacy;
