import React from 'react';
import {isLoading} from "../api/Atoms";
import {useRecoilValue} from "recoil";
import "../style/loader.css"
import {Circles} from "react-loader-spinner";

const Loader = () => {
    const loading = useRecoilValue(isLoading)

    const Modal = () => (
        <div>
            <Circles
                visible={true}
                height="100"
                width="100"
                color="#61bdd5"
                ariaLabel="three-circles-loading"
                wrapperStyle={{}}
                wrapperClass="Loader"
            />
        </div>
    )

    return (
        <div>
            {loading ? <Modal/> : null}
        </div>
    );
};

export default Loader;
